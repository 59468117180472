import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuDTO } from 'src/app/shared/models/menu-dto';
import { navItems } from 'src/app/_nav';

@Component({
  selector: 'app-mapa-site',
  templateUrl: './mapa-site.component.html',
  styleUrls: ['./mapa-site.component.css']
})
export class MapaSiteComponent implements OnInit {

  menus: MenuDTO[] = [];

  constructor(private router: Router) { }

  ngOnInit() {
    this.menus = navItems;
  }

  abrirEndereco(submenu: MenuDTO) {
    if (submenu.rota) {
      this.router.navigate([submenu.rota]);
    } else if (submenu.link) {
      window.open(submenu.link, submenu.target);
    }
  }

}
