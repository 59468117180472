import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuDTO } from 'src/app/shared/models/menu-dto';
import { navItems } from 'src/app/_nav';

@Component({
  selector: 'app-pesquisa',
  templateUrl: './pesquisa.component.html',
  styleUrls: ['./pesquisa.component.css',
    '../../../../node_modules/font-awesome/css/font-awesome.min.css']
})
export class PesquisaComponent implements OnInit {

  users: any[] = [{ name: 'Receitas' }, { name: 'Despesas' }, { name: 'Responsabilidade Fiscal' }, { name: 'Gestão Estadual' }];
  userFilter: any = { name: '' };
  texto: any = '';
  resultados: MenuDTO[];

  menus: MenuDTO[] = [];

  constructor() {

  }

  ngOnInit() {
    this.menus = navItems;
  }

  buscaTexto() {
    this.resultados = new Array();
    const texto = this.texto.toUpperCase();

    if (texto !== '') {
      this.menus.forEach(e => {
        if (e.submenus) {
          e.submenus.forEach(sub => {
            sub.submenus.forEach(ssub => {
              if ((ssub.descricao.toUpperCase().search(texto) !== -1 ||
                (ssub.link ? ssub.link : "").toUpperCase().search(texto) !== -1 ||
                (ssub.rota ? ssub.rota : "").toUpperCase().search(texto) !== -1)
                && (ssub.rota || ssub.link)) {
                this.resultados.push(ssub);
              }
            });          
          });
        }
      });
    }
  }

}

