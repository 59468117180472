import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from './app.service';
import { MenuDTO } from './shared/models/menu-dto';
import { navItems } from './_nav';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'Portal da Transparência do BADESC';
  menus: MenuDTO[] = [];
  public menuAberto: MenuDTO;

  @ViewChild("insideElement") insideElement;

  constructor(private router: Router) { }

  ngOnInit() {
    this.menus = navItems;
  }

  abrirMenu(menu: MenuDTO) {
    if (menu != this.menuAberto
        || !this.menuAberto) {
        this.menuAberto = menu;
    } else {
      this.menuAberto = null;
    }
  }

  isMenuBlockNone(menu: MenuDTO) {
    return this.menuAberto == menu ? 'block' : 'none';
  }

  isMenuAberto(menu: MenuDTO) {
    return this.menuAberto == menu;
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    const clickedInside = this.insideElement.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.menuAberto = null;
    }
  }

  abrirEndereco(submenu: MenuDTO) {
    if (submenu.rota) {
      this.router.navigate([submenu.rota]);
    } else if (submenu.link) {
      window.open(submenu.link, submenu.target);
    }
  }

}
