
export const navItems: any[] = [
  {
    oid: 1,
    descricao: 'Receita',
    classe: 'botao botao-1',
    classeMapa: 'sitemap-titulo titulo-receita',
    classeSubmenu: 'submenu submenu-1',
    rota: '#',
    submenus: [
        {
            submenus: [
                {
                    oid: 6,
                    descricao: 'Principais consultas',
                    classe: 'titulo'
                },
                {
                    oid: 7,
                    descricao: 'Receitas da Empresa',
                    rota: '/receitas'
                },
                {
                    oid: 8,
                    descricao: 'Orçamento da Receita',
                    rota: '/receitas/orcamento'
                }
            ]
        }
    ]
  },
  {
    oid: 2,
    descricao: 'Despesa',
    classe: 'botao botao-2',
    classeMapa: 'sitemap-titulo titulo-despesa',
    classeSubmenu: 'submenu submenu-2',
    rota: '#',
    submenus: [
        {
            submenus: [
                {
                    oid: 9,
                    descricao: 'Principais consultas',
                    classe: 'titulo'
                },
                {
                    oid: 9,
                    descricao: 'Despesa da Empresa',
                    rota: '/despesas'
                },
                {
                    oid: 9,
                    descricao: 'Orçamento da Despesa',
                    rota: '/despesas/orcamento'
                }
            ]
        },
        {
            submenus: [
                {
                    oid: 10,
                    descricao: 'Licitações e Contratos',
                    classe: 'titulo'
                },
                {
                    oid: 11,
                    descricao: 'Licitações',
                    link: 'http://www.badesc.gov.br/portal/licitacoes.jsp',
                    target: '_blank'
                },
                {
                    oid: 12,
                    descricao: 'Leilões',
                    link: 'http://www.badesc.gov.br/portal/leiloes.jsp',
                    target: '_blank'
                },
                {
                    oid: 13,
                    descricao: 'Contratos',
                    rota: '/despesas/contratos'
                },
                {
                    oid: 14,
                    descricao: 'Patrocínios',
                    rota: '/despesas/patrocinios'
                }
            ]
        },
        {
            submenus: [
                {
                    oid: 15,
                    descricao: 'Empregados Públicos',
                    classe: 'titulo'
                },
                {
                    oid: 16,
                    descricao: 'Remuneração e Dados Funcionais',
                    rota: '/despesas/dadosFuncionais'
                },
                {
                    oid: 17,
                    descricao: 'Movimentação de Pessoal',
                    rota: '/despesas/movimentacaoPessoal'
                },
                {
                    oid: 18,
                    descricao: 'Diárias',
                    rota: '/despesas/diarias'
                }
            ]
        }
    ]
  },
  {
    oid: 3,
    descricao: 'Responsabilidade Fiscal',
    classe: 'botao botao-3',
    classeMapa: 'sitemap-titulo titulo-responsabilidade',
    classeSubmenu: 'submenu submenu-3',
    rota: '#',
    submenus: [
        {
            submenus: [
                {
                    oid: 19,
                    descricao: 'Prestação de Contas',
                    classe: 'titulo'
                },
                {
                    oid: 20,
                    descricao: 'Prestação de Contas Anual',
                    rota: '/responsabilidadeFiscal/prestacaoContas'
                },
                {
                    oid: 21,
                    descricao: 'Aplicações Financeiras',
                    rota: '/responsabilidadeFiscal/aplicacoesInicio'
                }
            ]
        }
    ]
  },
  {
    oid: 4,
    descricao: 'Gestão Estadual',
    classe: 'botao botao-4',
    classeMapa: 'sitemap-titulo titulo-gestao',
    classeSubmenu: 'submenu submenu-4',
    rota: '#',
    submenus: [
        {
            submenus: [
                {
                    oid: 22,
                    descricao: 'A Empresa',
                    classe: 'titulo'
                },
                {
                    oid: 23,
                    descricao: 'Estrutura Organizaciona',
                    rota: '/gestaoEstadual/estruturaOrganizacional'
                },
                {
                    oid: 24,
                    descricao: 'Composição Acionária',
                    rota: '/gestaoEstadual/composicaoAcionaria'
                },
                {
                    oid: 25,
                    descricao: 'Competências',
                    rota: '/gestaoEstadual/competencias'
                },
                {
                    oid: 26,
                    descricao: 'Estatuto Social',
                    rota: '/gestaoEstadual/estatutoSocial'
                },
                {
                    oid: 27,
                    descricao: 'Contrato de Gestão',
                    rota: '/gestaoEstadual/contratoGestao'
                },
                {
                    oid: 28,
                    descricao: 'Regimento Interno',
                    rota: '/gestaoEstadual/regimentoInterno'
                },
                {
                    oid: 29,
                    descricao: 'Dicionário de Termos Técnicos',
                    rota: '/gestaoEstadual/dicTermosTecnicos'
                },
                {
                    oid: 30,
                    descricao: 'Regimento do Comitê de Auditoria Estatutário',
                    rota: '/gestaoEstadual/regimentoComite'
                },
                {
                    oid: 31,
                    descricao: 'Administração, Comitês e Conselho Fiscal',
                    rota: '/gestaoEstadual/administracao'
                },
                {
                    oid: 32,
                    descricao: 'Legislação',
                    rota: '/gestaoEstadual/legislacao'
                }
            ]
        },
        {
            submenus: [
                {
                    oid: 33,
                    descricao: 'Governança',
                    classe: 'titulo'
                },
                {
                    oid: 34,
                    descricao: 'Carta Anual de Governança Corporativa',
                    rota: '/gestaoEstadual/cartaGovernanca'
                },
                {
                    oid: 35,
                    descricao: 'Atas e Comunicados',
                    rota: '/gestaoEstadual/atas'
                },
                {
                    oid: 36,
                    descricao: 'Diário Oficial do Estado de SC',
                    link: 'https://doe.sea.sc.gov.br',
                    target: '_blank'
                },
                {
                    oid: 37,
                    descricao: 'Políticas',
                    classe: 'titulo'
                },
                {
                    oid: 38,
                    descricao: 'Carta Anual de Políticas Públicas',
                    rota: '/gestaoEstadual/cartaPublica'
                },
                {
                    oid: 39,
                    descricao: 'Política de Dividendos',
                    rota: '/gestaoEstadual/politicaDividendos'
                },
                {
                    oid: 40,
                    descricao: 'Política de Divulgação de Informações',
                    rota: '/gestaoEstadual/politicaDivulgacao'
                },
                {
                    oid: 59,
                    descricao: 'Política de Responsabilidade Social, Ambiental e Climática da Agência - PRSAC',
                    rota: '/gestaoEstadual/politicaResponsabilidadeSocialAmbientalClimatica'
                },
                {
                    oid: 41,
                    descricao: 'Políticas de Transações com Partes Relacionada',
                    rota: '/gestaoEstadual/politicaTransacao'
                },
                {
                    oid: 42,
                    descricao: 'Políticas de Porta-Vozes',
                    rota: '/gestaoEstadual/politicaPortaVoz'
                },
                {
                    oid: 43,
                    descricao: 'Regulamento de Licitações e Contratos',
                    rota: '/gestaoEstadual/regulamentoLicitacoesContratos'
                },
                {
                    oid: 44,
                    descricao: 'Planos, Relatórios e Indicadores',
                    classe: 'titulo'
                },
                {
                    oid: 45,
                    descricao: 'Indicadores de Gestão',
                    rota: '/gestaoEstadual/indicadores'
                },
                {
                    oid: 46,
                    descricao: 'Relatório de Sustentabilidade',
                    rota: '/gestaoEstadual/sustentabilidade'
                },
                {
                    oid: 47,
                    descricao: 'Plano de Negócios Anual',
                    rota: '/gestaoEstadual/planoNegocios'
                },
                {
                    oid: 48,
                    descricao: 'Estratégias de Longo Prazo',
                    rota: '/gestaoEstadual/estrategia'
                },
                {
                    oid: 49,
                    descricao: 'Relatório de Ouvidoria',
                    rota: '/gestaoEstadual/ouvidoria'
                },
                {
                    oid: 50,
                    descricao: 'Relatório de Linhas Subsidiadas',
                    rota: '/gestaoEstadual/relatorioLinhasSubsidiadas'
                }
            ]
        },
        {
            submenus: [
                {
                    oid: 51,
                    descricao: 'Patrimônio Público',
                    classe: 'titulo'
                },
                {
                    oid: 52,
                    descricao: 'Bens Móveis',
                    rota: '/gestaoEstadual/bensMoveis'
                },
                {
                    oid: 53,
                    descricao: 'Estoque e Almoxarifado',
                    rota: '/gestaoEstadual/estoqueAlmoxarifado'
                },
                {
                    oid: 54,
                    descricao: 'Relatório de Aquisições',
                    rota: '/gestaoEstadual/aquisicao'
                },
                {
                    oid: 55,
                    descricao: 'Relatório de Cessões, Permutas e Doações de Bens',
                    rota: '/gestaoEstadual/cessao'
                },
                {
                    oid: 56,
                    descricao: 'Gestão de Risco',
                    classe: 'titulo'
                },
                {
                    oid: 57,
                    descricao: 'Estrutura de Risco',
                    rota: '/gestaoEstadual/estruturaRisco'
                },
                {
                  oid: 58,
                  descricao: 'Relatórios de Controles Internos e Riscos',
                  rota: '/gestaoEstadual/relatorioControlesInternosRiscos'
                }
            ]
        }
    ]
  },
  {
    oid: 1,
    descricao: 'Pesquisar',
    classe: 'botao botao-5',
    classeSubmenu: 'submenu submenu-5',
    rota: '/pesquisar',
    lvPesquisa: true
  },


];
