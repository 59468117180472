import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrincipalComponent } from './pages/principal/principal.component';
import { PerguntasFrequentesComponent } from './pages/perguntas-frequentes/perguntas-frequentes.component';
import { MapaSiteComponent } from './pages/mapa-site/mapa-site.component';
import { SobrePortalComponent } from './pages/sobre-portal/sobre-portal.component';
import { PesquisaComponent } from './pages/pesquisa/pesquisa.component';

const routes: Routes = [
  // configurando o carregamento do module quando o path receitas for acionado.
  { path: '', component: PrincipalComponent},
  { path: 'receitas', loadChildren: './pages/receitas/receitas.module#ReceitasModule'},
  { path: 'despesas', loadChildren: './pages/despesas/despesas.module#DespesasModule'},
  { path: 'responsabilidadeFiscal', loadChildren: './pages/responsabilidade-fiscal/responsabilidade-fiscal.module#ResponsabilidadeFiscalModule'},
  { path: 'gestaoEstadual', loadChildren: './pages/gestao-estadual/gestao-estadual.module#GestaoEstadualModule'},
  { path: 'perguntasFrequentes', component: PerguntasFrequentesComponent},
  { path: 'mapaSite', component: MapaSiteComponent},
  { path: 'sobrePortal', component: SobrePortalComponent},
  { path: 'pesquisar', component: PesquisaComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
