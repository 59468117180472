import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-perguntas-frequentes',
  templateUrl: './perguntas-frequentes.component.html'
})
export class PerguntasFrequentesComponent implements OnInit {

  faqs: Array<any>;

  constructor() { }

  ngOnInit() {
    this.faqs = [{pergunta: 'Como faço para acessar informações sobre as despesas da empresa?',
                 resposta: 'Na página inicial, selecione o menu Despesa e escolha as informações que deseja pesquisar.'},
                 {pergunta: 'Como faço para encontrar a prestação de contas anual da empresa?',
                  resposta: 'Na página inicial, selecione o menu Responsabilidade Fiscal e acesse o link da prestação de contas anual.'},
                 {pergunta: 'Como faço para conhecer as políticas adotadas na empresa?',
                  resposta: 'Na página inicial, selecione o menu Gestão e acesse os links de cada política disponibilizada.'},
                 {pergunta: 'Como faço para encaminhar sugestões, elogios, reclamações ou tirar dúvidas sobre o conteúdo e a navegação do Portal da Transparência?',
                  resposta: 'Caso você queira encaminhar sugestões, elogios, reclamações ou tirar dúvidas, entre em contato por meio do Fale Conosco no Portal da Transparência e utilize a ferramenta Deixe seu Recado.'},
                 {pergunta: 'Como faço para denunciar irregularidades, solicitar documentos ou informações relacionadas a empresa?',
                  resposta: 'Caso você queira fazer uma denúncia, uma reclamação, solicitar documentos ou informações, assim como dar sugestões ou elogios, entre em contato com a Ouvidoria Geral do Estado de Santa Catarina, através do Fale Conosco no Portal da Transparência ou por meio do endereço http://www.ouvidoria.sc.gov.br. Ao enviar a sua reivindicação, o sistema informará automaticamente o protocolo da Ouvidoria com o número do atendimento e o código de consulta na Internet. Guarde-os para futuras pesquisas.'},
                 {pergunta: 'Tenho dúvidas sobre como encontrar alguma informação que estou procurando?',
                  resposta: 'A ferramenta Pesquisar foi desenvolvida para facilitar a sua navegação no Portal da Transparência do Estado de Santa Catarina. O objetivo é orientar o cidadão, de um modo rápido e simples, para que possa encontrar as informações desejadas, como, por exemplo, a localização de consultas e relatórios, bem como definições relacionadas.'}];
  }

}
