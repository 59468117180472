import { NgModule } from '@angular/core';

import { CoreModule } from "./core/core.module";

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import {AccordionModule} from "ngx-accordion";
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { FilterPipeModule } from 'ngx-filter-pipe';

import { PrincipalComponent } from './pages/principal/principal.component';
import { PerguntasFrequentesComponent } from './pages/perguntas-frequentes/perguntas-frequentes.component';
import { MapaSiteComponent } from './pages/mapa-site/mapa-site.component';
import { SobrePortalComponent } from './pages/sobre-portal/sobre-portal.component';
import { PesquisaComponent } from './pages/pesquisa/pesquisa.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { TypeaheadModule, BsDatepickerModule, ModalModule } from 'ngx-bootstrap';
import { ComponentNameComponent } from './component-name/component-name.component';


@NgModule({
  declarations: [
    AppComponent,
    PrincipalComponent,
    PerguntasFrequentesComponent,
    MapaSiteComponent,
    SobrePortalComponent,
    PesquisaComponent,
    ComponentNameComponent
  ],
  imports: [
    CoreModule,
    AppRoutingModule,
    AccordionModule,
    FilterPipeModule,
    BrowserModule,
    FormsModule,
    AngularFontAwesomeModule,
    ModalModule.forRoot(),
    TypeaheadModule.forRoot(),
    BsDatepickerModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
